// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD0ktZx94hGtbHi7dw2N2H9mEAQPO6Txr0",
    databaseURL: "https://notificacionpushdev.firebaseio.com",
    messagingSenderId: "514261864933",
    projectId: "notificacionpushdev",
    appId: "1:514261864933:web:c1cc117677e037bf0b5f0c",
    PublicVapidKey: "BHVFl0D2ZFpy7pRHfYDN1Sa1yMzn1P8cxZiEZ94CUwBIMC2aKK3TPzD5vgNzp8W4POU1x_UcE0jxIiKYEZzC1pg",
    SistemaId: '65507ECF-249E-454B-A95A-0061BA0FA2BA'
  },
  authentication: {
    ClienteId: 'HOJADERUTAJS',
    SistemaId: '65507ECF-249E-454B-A95A-0061BA0FA2BA',
    SistemaIdV: 'E455F2A9-5368-464E-A294-0F9DEDA12C85',
    Stage: true
  },//65507ECF-249E-454B-A95A-0061BA0FA2BA
  webServices: {
    HojaRuta: 'https://scehojaderutaapi.vestadev-accelerate.com/api',
    HojaRutaOld: 'http://aduanaswebapi.grupovesta.com/',
    Personas: 'http://scepersonasapi.vestadev-accelerate.com/api',
    AnalisisDeRed: 'http://sceanalisisderedapi.vestadev-accelerate.com/api',
    qrscan: 'http://qrscan.grupovesta.com/api',
    Remisiones: 'http://sceremisionesapi.vestadev-accelerate.com/api',
    hojaderutalegacy: 'http://scehojaderutalegacyapi.vestadev-accelerate.com/api',
    ultimamilla: 'http://sceultimamillaapi.vestadev-accelerate.com/api',

  },
  web: {
    Remisiones: 'http://scecontroldedocumentosweb.vestadev-accelerate.com',
    EventoSitios: 'https://sceeventositiosweb.vestadev-accelerate.com/'
  },
  FileShareConfig: { CodigoSistema: '24' }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

const firebaseConfig = {
  
};
